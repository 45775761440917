<template>
  <div class="support-list">
    <div class="tsjy-list">
      <div v-for="(list, index) of lists" :key="index" class="list-box">
        <div class="infor-left">
          <div class="type">
            <p>
              <i v-if="list.sfck === '0'" class="icon-dont"></i>
              <a>【{{(index + 1) }}】</a>
            </p>
          </div>
          <div class="infor-detail">
            <div :class="['infor-title']">
              {{ list.wtbt }}
            </div>
            <div class="infor-con">
              <p>{{ list.wtms }}</p>
              <a @click="updateTsjySfck(list)">查看详情</a>
            </div>
            <div class="infor-time">{{ list.gxsj }}发起</div>
          </div>
        </div>
        <div class="infor-right">
          <span v-if="list.zt==='1'" class="slbm">反馈部门</span>
          <span v-if="list.zt==='1'" class="bm">{{ decodeURIComponent(list.hfbm) }}</span>
          <span :class="[list.zt==='0'? 'wsl': 'ysl']">{{ list.zt | stateTS() }}</span>
        </div>
        <el-dialog :title="'留言详情'" :visible.sync="list.dialogVisible">
          <div class="dialog-box">
            <div class="tsjy-details">
              <div class="details-row">
                <div class="item-name">
                  留言对象名称
                </div>
                <div class="item-con">
                  {{ list.dymc === null ? '平台' : list.dymc }}
                </div>
              </div>
              <div class="details-row">
                <div class="item-name">
                  标题
                </div>
                <div class="item-con">
                  {{ list.wtbt }}
                </div>
              </div>
              <div class="details-row">
                <div class="item-name">
                  留言内容
                </div>
                <div class="item-con">
                  {{ list.wtms }}
                </div>
              </div>
              <div v-if="list.fjList!==undefined&&list.fjList!=null&&list.fjList!==''" class="details-row">
                <div class="item-name">
                  附件
                </div>
                <div class="item-con">
                  <div class="pic-box">
                    <el-image v-for="(item,index) in list.fjList"
                              :preview-src-list="list.fjList"
                              :src="item"
                              :key="index"
                              style="width: 100px; height: 100px">
                    </el-image>
                  </div>
                </div>
              </div>
            </div>
            <div class="hf-detail">
              <div class="hf-title">
                <div class="title-name">
                  <i v-if="list.zt==='0'" class="type-yb"></i>
                  <i v-if="list.zt==='1'" class="type-js"></i>
                  <a v-if="list.zt==='1'">{{ decodeURIComponent(list.hfbm) }}</a>
                  <a v-if="list.zt==='0'">暂未回复</a>
                  <a v-if="list.zt==='1'">回复</a>
                </div>
                <div v-if="list.hfsj!=null" class="hf-time">
                  时间：{{ list.hfsj }}
                </div>
              </div>
              <div class="hf-con" v-if="list.hf !== '' && list.hf !== null && list.hf !== undefined">
                <p>
                  {{ list.hf }}
                </p>
              </div>
            </div>
          </div>

        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Tsjy from "./tsjy";
import {queryWck, updateSfck} from "@/api/personService";

export default {
  components: {Tsjy},
  filters: {
    defaultValue(val) {
      if (val === 3) {
        return "超时"
      } else {
        return '未超时'
      }

    },
    stateTS(val) {
      if (val === '0') {
        return '待回复'
      } else if (val === '1') {
        return '已回复'
      }
    },
  },
  props: {
    listData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    'listData': {
      handler(val) {
        if (val !== undefined) {
          this.lists = val;
          for (let u = 0; u < this.lists.length; u++) {
            if (this.lists[u].fj != null && this.lists[u].fj !== undefined && this.lists[u].fj !== '') {
              this.lists[u].fjList = JSON.parse(this.lists[u].fj)
            }

          }

        }
      },
      immediate: true,
    }
  },
  data() {
    return {
      isFollow: false,
      lists: this.listData,
      showTsjy: false,
      tsjy: {},
    }
  },
  methods: {
    /**
     * 查看留言详情
     * @param data
     */
    updateTsjySfck(data) {
      data.dialogVisible = true
      updateSfck(data).then(res => {
        data.sfck = '1'
        queryWck({}).then(res => {
          const tsjy = this.$store.state.tsWckl;
          for (let i = 0; i < tsjy.length; i++) {
            if (tsjy[i].title === '我的留言') {
              tsjy[i].showTag = res.data > 0;
              tsjy[i].count = res.data;
            }
          }
          // this.$store.commit("updateTsWckl", tsjy);
        })
      })
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss">
.support-list {
  .tsjy-list {
    .list-box {
      width: 100%;
      height: 120px;
      border-bottom: 1px solid #CFDEE6;
      padding: 20px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;

      .infor-left {
        display: flex;
        flex-direction: row;
        width: calc(100% - 211px);
        box-sizing: border-box;

        .type {
          width: 74px;

          p {
            line-height: 18px;
            height: 18px;
            display: flex;
            align-items: center;

            .icon-dont {
              display: inline-block;
              width: 4px;
              height: 4px;
              background: #FF3333;
              border-radius: 50%;
              margin-right: 6px;
            }

            a {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              margin-left: auto;
            }

            .yisl {
              color: #999999;
            }

            .other {
              color: #333333;
            }
          }

        }

        .infor-detail {
          width: calc(100% - 75px);
          padding: 0px 30px 0px 20px;
          box-sizing: border-box;

          .infor-title {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }

          .infor-title2 {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }

          .infor-con {
            display: flex;
            flex-direction: row;
            font-size: 14px;
            height: 16px;
            line-height: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            margin-top: 16px;

            p {
              color: #666666;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              width: 84%;
            }

            a {
              color: #0080FF;
              margin-left: auto;
            }
          }

          .infor-time {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            text-align: right;
            margin-top: 15px;
          }
        }
      }

      .infor-right {
        width: 210px;
        height: 100%;
        box-shadow: -1px 0px 0px 0px #CFDEE6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .slbm {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 16px;
        }

        .bm {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
          margin-top: 12px;
        }

        .ysl {
          width: 80px;
          height: 24px;
          line-height: 24px;

          border-radius: 12px;
          display: block;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          margin-top: 15px;
          background: #00CC88;
        }

        .wsl {
          width: 80px;
          height: 24px;
          line-height: 24px;

          border-radius: 12px;
          display: block;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          margin-top: 15px;
          background: #ccaa00;
        }

        .yhf {
          width: 80px;
          height: 24px;
          line-height: 24px;

          border-radius: 12px;
          display: block;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          margin-top: 15px;
          background: #3399FF;
        }

        .dsl {
          width: 80px;
          height: 24px;
          line-height: 24px;

          border-radius: 12px;
          display: block;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          margin-top: 15px;
          background: #ff0900;
        }
      }
    }

    .el-dialog {
      width: 800px;

      .el-dialog__body {
        padding: 40px 0px 0px 0px;
      }

      .el-dialog__header {
        padding: 20px 20px 20px 40px;
        box-sizing: border-box;
        background: #EBF2F5;

        .el-dialog__title {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 20px;
        }
      }

      .dialog-box {
        .stepOut {
          width: 100%;
          height: 70px;
          display: flex;
          justify-content: center;

          .stepItem {
            width: 300px;
            height: 70px;
            float: left;
            font-family: SimSun;
            font-size: 16px;
            position: relative;

            .icon {
              width: 32px;
              height: 32px;
              background: url(../assets/images/grzx/icon-unfinish.png) no-repeat center center, #FFFFFF;
              background-size: 10px 10px;
              border: 1px solid #CCE6FF;
              border-radius: 50%;
              position: relative;
              top: -9px;
              z-index: 888;
              margin-left: 10px;
            }

            .active {
              background: url(../assets/images/grzx/icon-finish.png) no-repeat center center, #FFFFFF;
              background-size: 24px 24px;
            }

            .line {
              position: absolute;
              top: 6px;
              left: 35px;
              border-bottom: 3px solid #CFDAE6;
              width: 280px;
              z-index: 111;
            }

            .lineActive {
              border-bottom: 3px solid #0080FF;
            }

            .stepStatus {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #999999;
              line-height: 14px;
              letter-spacing: 2px;
            }

            .statusActive {
              color: #0080FF;
            }

          }

          .stepItem:last-child {
            width: 60px;
          }
        }

        .tsjy-details {
          padding: 0px 60px 0px 60px;
          box-sizing: border-box;

          .details-row {
            display: flex;
            flex-direction: row;
            font-size: 14px;
            font-family: Microsoft YaHei;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 34px;

            .item-name {
              color: #999999;
              width: 90px;
              text-align: right;
            }

            .item-con {
              color: #000000;
              width: calc(100% - 92px);
              padding-left: 40px;
              box-sizing: border-box;

              .pdf-box {
                display: flex;
                flex-direction: row;

                a {
                  background: url(../assets/images/grzx/icon-PDF.png) no-repeat left center;
                  background-size: 20px 20px;
                  line-height: 20px;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #0080FF;
                  padding-left: 25px;
                  margin-right: 10px;
                }
              }

              .pic-box {
                display: flex;
                flex-direction: row;
                margin-top: 20px;

                .pic {
                  width: 80px;
                  height: 80px;
                  background: #EBF2F5;
                  border: 1px solid #CFDEE6;
                  margin-right: 10px;
                }
              }
            }
          }
        }

        .hf-detail {
          width: 799px;
          min-height: 120px;
          background: url(../assets/images/tsjy/img-bg2.png) no-repeat left top, #EBF2F5;
          background-size: 130px 120px;
          border: 1px solid #CFDEE6;
          border-radius: 0px 0px 4px 4px;
          padding: 20px 77px 20px 100px;
          box-sizing: border-box;

          .hf-title {
            display: flex;
            flex-direction: row;

            .title-name {
              display: flex;
              flex-direction: row;

              i {
                display: inline-block;
                width: 20px;
                height: 20px;

                background-size: 20px 20px;
              }

              .type-yb {
                background: url(../assets/images/grzx/icon-hf.png) no-repeat center center;
              }

              .type-bh {
                background: url(../assets/images/grzx/icon-bh.png) no-repeat center center;
              }

              .type-js {
                background: url(../assets/images/grzx/icon-js.png) no-repeat center center;
              }

              a {
                line-height: 20px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                padding-left: 12px;
              }
            }

            .hf-time {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 16px;
              margin-left: auto;
            }
          }

          .hf-con {
            margin-top: 15px;
            padding-left: 32px;

            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              line-height: 24px;
              text-align: justify;
            }
          }
        }
      }
    }

  }


}

</style>
